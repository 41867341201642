<template>
  <b-card>
    <div>
      <div class="text-black text-[20px] mb-2 font-[600]">
        Daftar Partner
      </div>
    </div>
    <div>
      <div class="md:flex gap-[1rem]">
        <div
          v-for="(data, index) in dataCard"
          :key="index"
          class="md:w-[33%]"
        >
          <CardPartner
            :name-card="data.nameCard"
            :icon-card="data.icon"
            :total-data="data.total"
            :weekly-data="data.this_week"
            :yesterday-data="data.yesterday"
          />
        </div>
      </div>
      <div>
        <TablePartner
          :items="itemsTable"
          :loading="isLoading"
          @handleSearchPartner="handleSearchPartner"
          @getNextData="getDataDashboard()"
        />
      </div>
    </div>
  </b-card>
</template>
<script>
import moment from 'moment'
import CardPartner from './components/CardPartner.vue'
import TablePartner from './components/TablePartner.vue'
import {
  apiDahsboardKomchat, apiRegisteredGrowthKomchat, apiVerifiedGrowthKomchat, apiBroadcastGrowthKomchat,
} from './services/api.komchat.service'

export default {
  components: {
    CardPartner,
    TablePartner,
  },
  data() {
    return {
      date: moment().format('DD-MM-YYYY'),
      dataCard: [],
      search: '',
      itemsTable: [],
      pagination: {
        page: 1,
        per_page: 15,
        total: 0,
        total_page: 0,
      },
      lastData: false,
      isLoading: false,
    }
  },
  mounted() {
    this.getDataDashboard()
    this.getVerifiedGrowthCard()
    this.getRegisteredGrowthCard()
    this.getBroadcastGrowthCard()
  },
  methods: {
    handleSearchPartner: _.debounce(function searchData(value) {
      this.search = value
      this.lastData = false
      this.pagination.page = 1
      this.itemsTable = []
      this.getDataDashboard()
    }, 1000),
    async getDataDashboard() {
      try {
        if (this.isLoading || this.lastData) return
        this.isLoading = true
        const { data } = await apiDahsboardKomchat({ search: this.search, limit: this.pagination.per_page, offset: this.pagination.page })
        this.itemsTable = [...this.itemsTable, ...data.data]
        this.pagination.page += 1
        if (data.data.length < this.pagination.per_page) {
          this.lastData = true
        } else {
          this.lastData = false
        }
        this.isLoading = false
      } catch (err) {
        this.$toast_error({ message: 'Gagal ambil data dashboard' })
        console.error(err)
        this.isLoading = false
      }
    },
    async getRegisteredGrowthCard() {
      try {
        const { data } = await apiRegisteredGrowthKomchat({ date: this.date })
        const dataUser = {
          nameCard: 'User',
          icon: 'https://storage.googleapis.com/komerce/assets/icons/user-square-api.svg',
          ...data.data,
        }
        this.dataCard.unshift(dataUser)
      } catch (err) {
        this.$toast_error({ message: 'Gagal ambil data growth user' })
        console.error(err)
      }
    },
    async getVerifiedGrowthCard() {
      try {
        const { data } = await apiVerifiedGrowthKomchat({ date: this.date })
        const verified = {
          nameCard: 'User Verified',
          icon: 'https://storage.googleapis.com/komerce/assets/icons/user-square-api.svg',
          ...data.data,
        }
        this.dataCard.unshift(verified)
      } catch (err) {
        this.$toast_error({ message: 'Gagal ambil data verified user' })
        console.error(err)
      }
    },
    async getBroadcastGrowthCard() {
      try {
        const { data } = await apiBroadcastGrowthKomchat({ date: this.date })
        const broadcast = {
          nameCard: 'Broadcast Send',
          icon: 'https://storage.googleapis.com/komerce/assets/icons/user-square-api.svg',
          ...data.data,
        }
        this.dataCard.push(broadcast)
      } catch (err) {
        this.$toast_error({ message: 'Gagal ambil data broadcast user' })
        console.error(err)
      }
    },
  },
}
</script>
